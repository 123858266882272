// Import our CSS
import '../css/main.css';
import './scripts/components/';
import Alpine from 'alpinejs';
import '../../templates/_components/menu/mmenuJs';
// import Swiper from "swiper/bundle";

// Function to handle userAgentData
function handleUserAgentData() {
  if (navigator.userAgentData) {
    navigator.userAgentData.getHighEntropyValues(['platformVersion']).then((ua) => {
      if (navigator.userAgentData.platform === 'Windows') {
        const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
        if (majorPlatformVersion >= 13) {
          console.log('Windows 11 or later');
          document.documentElement.classList.add('win11');
        } else if (majorPlatformVersion > 0) {
          console.log('Windows 10');
          document.documentElement.classList.add('win10');
        } else {
          console.log('Before Windows 10');
          document.documentElement.classList.add('win');
        }
      } else {
        console.log('Not running on Windows');
        document.documentElement.classList.add('nowin');
      }
    });
  } else {
    const ua = navigator.userAgent;
    if (/Windows NT 10\.0/.test(ua)) {
      console.log('Windows 10');
      document.documentElement.classList.add('win10');
    } else if (/Windows NT/.test(ua)) {
      console.log('Before Windows 10');
      document.documentElement.classList.add('win');
    } else {
      console.log('Not running on Windows');
      document.documentElement.classList.add('nowin');
    }
  }
}

// Function to capture and store UTM parameters
function captureAndStoreUTMParams() {
  console.log('Capturing UTM parameters');
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  let atLeastOneUTMExists = utmParams.some((param) => urlParams.has(param));

  utmParams.forEach((param) => {
    const value = urlParams.get(param);
    if (value) {
      console.log(`Storing ${param}: ${value}`);
      sessionStorage.setItem(param, value);
    } else if (atLeastOneUTMExists) {
      console.log(`Storing empty ${param}`);
      sessionStorage.setItem(param, '');
    }
  });
}

// Function to push UTM data to dataLayer
function pushUTMToDataLayer() {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  let utmData = {};

  utmParams.forEach((param) => {
    const value = sessionStorage.getItem(param);
    if (value !== null) {
      utmData[param] = value;
    }
  });

  // Only push to dataLayer if we have any UTM data
  if (Object.keys(utmData).length > 0) {
    console.log('Pushing UTM data to dataLayer:', utmData);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(utmData);
  } else {
    console.log('No UTM data to push to dataLayer');
  }
}

// Function to load CSS files dynamically
function loadCSS(href, beforeElement = null) {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    link.media = 'all';
    link.onload = () => resolve();
    link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`));
    if (beforeElement) {
      document.head.insertBefore(link, beforeElement);
    } else {
      document.head.appendChild(link);
    }
  });
}

function loadAlgoliaCSS() {
  const mainCssElement = document.querySelector('link[href*="main-"][rel="stylesheet"]');
  return Promise.all([
    loadCSS('https://cdn.jsdelivr.net/npm/@algolia/autocomplete-theme-classic', mainCssElement),
    loadCSS('https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/algolia-min.css', mainCssElement),
  ]);
}

// Function to initialize dynamic imports based on URL path
function initializeDynamicImports(path, urlSegment1, urlSegment2) {
  if (path.startsWith('/offers') || path.startsWith('/ar/offers') || urlSegment2 === 'offers') {
    console.log('Offers page');
    loadAlgoliaCSS().then(() => {
      import('./scripts/algoliaOffers.js').then(({ initializeAlgolia }) => {
        initializeAlgolia();
      });
    });
  } else if (path.startsWith('/locations') || path.startsWith('/ar/locations') || urlSegment2 === 'locations') {
    console.log('Locations page');
    loadAlgoliaCSS().then(() => {
      import('./scripts/algoliaLocations.js').then(({ initializeAlgolia }) => {
        initializeAlgolia();
      });
    });
  } else if (path.startsWith('/used') || path.startsWith('/ar/used') || urlSegment1 === 'used') {
    console.log('Used page');
    loadAlgoliaCSS().then(() => {
      import('./scripts/algoliaUsed.js').then(({ initializeAlgolia }) => {
        initializeAlgolia();
      });
    });
  }
}

// Function to initialize lightGallery
function initializeLightGallery() {
  const lightGalleryElement = document.getElementById('lightgallery');
  if (lightGalleryElement) {
    import('./scripts/lightGallery.js').then(({ initializeLightGallery }) => {
      initializeLightGallery();
    });
  }
}

// Function to initialize Swiper
function initializeSwiper() {
  const heroSliderElement = document.getElementById('hero-slider');
  const modelSliderElement = document.getElementById('model-slider');
  const relatedOffersSliderElement = document.getElementById('related-offers-slider');
  const relatedModelsSliderElement = document.getElementById('related-models-slider');
  const relatedLocationsSliderElement = document.getElementById('related-locations-slider');
  const featuredOffersSliderElement = document.getElementById('featured-offers-slider');
  const featuredModelsSliderElement = document.getElementById('featured-models-slider');

  if (
    heroSliderElement ||
    modelSliderElement ||
    relatedOffersSliderElement ||
    relatedModelsSliderElement ||
    relatedLocationsSliderElement ||
    featuredOffersSliderElement ||
    featuredModelsSliderElement
  ) {
    import('./scripts/swiper.js').then(({ initializeSwiper }) => {
      initializeSwiper();
    });
  }
}

// Function to initialize survey rating
function initializeSurveyRating() {
  const surveyElement = document.querySelector('[id*="surveyPages"]');

  if (surveyElement) {
    const currentLang = document.documentElement.lang || 'en';

    const ratingLabels = {
      en: {
        0.5: 'Very Poor',
        1: 'Poor',
        1.5: 'Fair',
        2: 'Below Average',
        2.5: 'Average',
        3: 'Good',
        3.5: 'Very Good',
        4: 'Excellent',
        4.5: 'Outstanding',
        5: 'Perfect',
      },
      ar: {
        0.5: 'ضعيف جداً',
        1: 'ضعيف',
        1.5: 'مقبول',
        2: 'أقل من المتوسط',
        2.5: 'متوسط',
        3: 'جيد',
        3.5: 'جيد جداً',
        4: 'ممتاز',
        4.5: 'استثنائي',
        5: 'مثالي',
      },
    };

    const ratingContainers = document.querySelectorAll('.star-rating');

    ratingContainers.forEach((container) => {
      const selectElement = container.querySelector('select');

      if (selectElement) {
        selectElement.classList.add('lr-hidden');

        const starRatingContainer = document.createElement('div');
        starRatingContainer.className = 'star-rating-container';
        container.appendChild(starRatingContainer);

        const starsContainer = document.createElement('div');
        starsContainer.className = 'stars-container';
        starRatingContainer.appendChild(starsContainer);

        const ratingTextElement = document.createElement('div');
        ratingTextElement.className = 'rating-label';
        ratingTextElement.textContent = currentLang === 'ar' ? 'اختر تقييماً' : 'Select a rating';
        starRatingContainer.appendChild(ratingTextElement);

        for (let i = 1; i <= 5; i++) {
          let star = document.createElement('span');
          star.classList.add('star');
          star.innerHTML = `<svg class="rating"><use href="/dist/assets/icons/custom/star-rating.svg#rating-star-empty"></use></svg>`;
          star.dataset.value = i;

          star.onclick = function (e) {
            const rect = e.target.getBoundingClientRect();
            const isRTL = document.dir === 'rtl';
            let isLeftHalf = isRTL ? e.clientX - rect.left > rect.width / 2 : e.clientX - rect.left < rect.width / 2;
            selectElement.value = isLeftHalf ? `${i - 0.5}` : `${i}`;
            selectElement.dispatchEvent(new Event('change'));

            updateStars(starsContainer, selectElement.value, ratingTextElement);
          };

          starsContainer.appendChild(star);
        }
      }
    });

    const commentsField = document.querySelector('[data-field-handle="comments"]');

    if (commentsField) {
      commentsField.style.display = 'none';
    }

    const selectElement = document.querySelector('select[name="fields[rating]"]');
    if (selectElement) {
      selectElement.addEventListener('change', function () {
        const selectedRating = this.value;
        const showCommentsConditions = ['0.5', '1', '1.5', '2', '2.5', '3'];

        if (showCommentsConditions.includes(selectedRating)) {
          commentsField.style.display = '';
        } else {
          commentsField.style.display = 'none';
        }
      });

      selectElement.dispatchEvent(new Event('change'));
    }

    function updateStars(container, value, ratingTextElement) {
      const selectedValue = parseFloat(value);
      const currentLabels = ratingLabels[currentLang];
      Array.from(container.children).forEach((star, index) => {
        const starValue = index + 1;
        const svgUseElement = star.querySelector('use');
        star.className = 'star';

        if (starValue <= selectedValue) {
          svgUseElement.setAttribute('href', `/dist/assets/icons/custom/star-rating.svg#rating-star-full`);
          star.classList.add('full');
        } else if (starValue - 0.5 === selectedValue) {
          svgUseElement.setAttribute('href', `/dist/assets/icons/custom/star-rating.svg#rating-star-half`);
          star.classList.add('half');
        } else {
          svgUseElement.setAttribute('href', `/dist/assets/icons/custom/star-rating.svg#rating-star-empty`);
        }
      });

      const labelText = currentLabels[value] || (currentLang === 'ar' ? 'اختر تقييماً' : 'Select a rating');
      ratingTextElement.textContent = labelText;
    }
  }
}

// Named function for event listener
function onDOMContentLoaded() {
  handleUserAgentData();
  captureAndStoreUTMParams();
  pushUTMToDataLayer();

  const path = window.location.pathname;
  const urlSegment1 = window.urlSegment1;
  const urlSegment2 = window.urlSegment2;

  initializeDynamicImports(path, urlSegment1, urlSegment2);
  initializeLightGallery();
  initializeSwiper();
  initializeSurveyRating();
}

// Add event listener
document.addEventListener('DOMContentLoaded', onDOMContentLoaded);

// Clean up on page unload
window.addEventListener('pagehide', function () {
  document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
});

// Start Alpine
Alpine.start();
