import smoothscroll from 'smoothscroll-polyfill';

// Apply the smooth scroll polyfill universally
smoothscroll.polyfill();

/**
 * Selects the SVG path element used to display scroll progress.
 * @type {SVGPathElement|null}
 */
const progressPath = document.querySelector('.backtotop path');

/**
 * Total length of the progress path, or 0 if path is not defined.
 * @type {number}
 */
const pathLength = progressPath ? progressPath.getTotalLength() : 0;

/**
 * Height of the footer element, used to adjust scrollable content height calculations.
 * @type {number}
 */
const footerHeight = document.querySelector('footer') ? document.querySelector('footer').offsetHeight : 0;

/**
 * Creates and returns a back-to-top functionality object.
 * @returns {Object} Object containing functions to manage back-to-top functionality.
 */
export function backtotop() {
  return {
    scrolled: false,
    height: 60,
    mobileOpen: false,

    /**
     * Initializes the progress path by setting initial styles and transition properties.
     */
    setup() {
      if (progressPath) {
        progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
        progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect(); // Trigger a reflow by accessing the bounding client rectangle
        progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
      }
    },

    /**
     * Updates the stroke offset of the progress path to reflect current scroll progress.
     */
    updateProgress() {
      const zoomLevel = parseFloat(window.getComputedStyle(document.documentElement).zoom) || 1;
      const scrollValue = window.scrollY * zoomLevel; // Adjust scroll value for zoom
      const maxScrollHeight = document.body.scrollHeight - window.innerHeight - footerHeight; // Maximum scrollable height
      const scrollHeight = maxScrollHeight * zoomLevel; // Adjust scroll height for zoom

      const progress = pathLength - (scrollValue * pathLength) / scrollHeight;
      const effectiveProgress = Math.max(0, Math.min(progress, pathLength)); // Ensure progress does not exceed path length and does not go negative

      if (progressPath) {
        progressPath.style.strokeDashoffset = effectiveProgress;
      }

      // console.log(
      //   `Zoom Level: ${zoomLevel}, Scroll Value: ${scrollValue}, Scroll Height: ${scrollHeight}, Progress: ${progress}, Effective Progress: ${effectiveProgress}, Footer Height: ${footerHeight}`,
      // );
    },

    /**
     * Handles the scroll event to update progress and toggle scrolled state.
     */
    scroll() {
      this.updateProgress();
      const scrollValue = window.scrollY;
      this.scrolled = scrollValue >= this.height;
    },

    /**
     * Scrolls the window to the top smoothly.
     * @returns {boolean} Always returns false.
     */
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return false;
    },
  };
}
