import smoothscroll from 'smoothscroll-polyfill';

// Apply the polyfill universally
smoothscroll.polyfill();

/**
 * Initializes and returns a navigation bar control object with scroll functionality specifically adjusted for different browsers.
 *
 * @param {string} topMenu The ID of the top menu HTML element.
 * @param {string} header The ID of the header HTML element.
 * @param {string} hero The ID of the hero section HTML element.
 * @returns {Object} The navigation bar control object.
 */
export function navbar(topMenu, header, hero) {
  /**
   * Safely retrieves the height of an HTML element by its ID.
   * @param {string} id The ID of the HTML element.
   * @returns {number} The height of the element, or 0 if the element is not found.
   */
  function safeGetHeight(id) {
    const element = document.getElementById(id);
    if (element) return element.offsetHeight;
    return 0; // Return 0 if element does not exist
  }

  /**
   * Calculates the cumulative vertical offset to the top of the specified element.
   * @param {HTMLElement} element The HTML element to calculate the offset for.
   * @returns {number} The cumulative vertical offset in pixels.
   */
  function getVerticalOffset(element) {
    let verticalOffset = 0;
    while (element) {
      verticalOffset += element.offsetTop || 0;
      element = element.offsetParent;
    }
    return verticalOffset;
  }

  // Detect if the browser is Samsung Internet or Windows.
  const userAgent = navigator.userAgent;
  const isSamsungInternet = /SamsungBrowser/i.test(userAgent);
  const isWindows = /Windows/i.test(userAgent);

  const navbarObj = {
    scrolledHeader: false,
    scrolledHero: false,
    offsets: {
      topMenu: safeGetHeight(topMenu),
      header: safeGetHeight(header),
      hero: safeGetHeight(hero),
    },
    mobileOpen: false,
    isChecked: false,
    megamenuOpened: false,
    openedMegamenu: '',
    scrollPageUp: true,
    isPageTop: false,
    lastPos: window.scrollY,

    /**
     * Handles the scroll event, updating the state of header and hero visibility based on the scroll position.
     */
    scroll() {
      const scrollValue = window.scrollY;
      this.scrolledHeader = scrollValue >= this.offsets.header;
      this.scrolledHero = window.matchMedia('(min-width: 1024px)').matches ? scrollValue >= this.offsets.hero : true;
      this.isPageTop = scrollValue > 1;
      this.scrollPageUp = this.lastPos > scrollValue;
      this.lastPos = scrollValue;
    },

    /**
     * Initializes click handlers for all `.scroll-link` elements to enable smooth scrolling.
     */
    initScrollAnchors() {
      document.querySelectorAll('.scroll-link[href^="#"]').forEach((trigger) => {
        trigger.onclick = (e) => {
          e.preventDefault();
          let zoomLevel = 1;
          if (isWindows) {
            // Apply zoom level adjustments only on Windows
            zoomLevel = parseFloat(window.getComputedStyle(document.documentElement).zoom) || 1;
          }
          const target = document.querySelector(trigger.getAttribute('href'));
          if (!target) return; // Safety check
          const targetOffset = getVerticalOffset(target) * zoomLevel;
          const headerOffset = this.offsets.header * zoomLevel;
          let scrollToPosition = targetOffset - headerOffset;
          if (isSamsungInternet) {
            scrollToPosition += 0;
          }
          window.scrollTo({
            top: scrollToPosition,
            behavior: 'smooth',
          });
        };
      });
    },
  };

  document.addEventListener('DOMContentLoaded', () => {
    navbarObj.offsets.topMenu = safeGetHeight(topMenu);
    navbarObj.offsets.header = safeGetHeight(header);
    navbarObj.offsets.hero = safeGetHeight(hero);
  });

  window.addEventListener('resize', () => {
    navbarObj.offsets.topMenu = safeGetHeight(topMenu);
    navbarObj.offsets.header = safeGetHeight(header);
    navbarObj.offsets.hero = safeGetHeight(hero);
  });

  return navbarObj;
}
