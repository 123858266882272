import Mmenu from 'mmenu-js';
import 'mmenu-js/dist/mmenu.css';
import './mburger.css';

/**
 * Initializes the mobile menu.
 * @function initMenu
 */
const initMenu = () => {
  // Add Arabic translations
  const arabicTranslations = {
    Menu: 'القائمة',
    'Open submenu': 'فتح القائمة الفرعية',
    'Close submenu': 'إغلاق القائمة الفرعية',
    cancel: 'إلغاء',
    'Cancel searching': 'إلغاء البحث',
    'Clear searchfield': 'مسح حقل البحث',
    'No results found.': 'لم يتم العثور على نتائج.',
    Search: 'بحث',
  };

  // Pass the Arabic translations to Mmenu.i18n() with the language handle
  Mmenu.i18n(arabicTranslations, 'ar');

  // Menu initialization
  let menu = undefined;
  let mobileMenuPosition = 'right';
  let mobileMenuTitle = 'Ali Alghanim and Sons';
  let mobileMenuTheme = 'dark';
  let language = 'en'; // Default language

  // Determine language based on document direction
  if (document.dir == 'rtl') {
    language = 'ar';
    mobileMenuPosition = 'left';
    mobileMenuTitle = 'علي الغانم وأولاده للسيارات';
  }

  /**
   * Represents the options for initializing the mobile menu.
   * @typedef {Object} MenuOptions
   * @property {Object} offCanvas - Options for the off-canvas behavior.
   * @property {string} offCanvas.position - The position of the off-canvas menu ('left' or 'right').
   * @property {Object} setSelected - Options for setting selected menu items.
   * @property {boolean} setSelected.hover - Whether to set menu items as selected on hover.
   * @property {Object} counters - Options for menu item counters.
   * @property {boolean} counters.add - Whether to add counters to menu items.
   * @property {string} theme - The theme of the menu ('dark' or 'light').
   * @property {Object} navbar - Options for the menu navbar.
   * @property {string} navbar.title - The title of the menu navbar.
   * @property {Array} navbars - Additional navigation bars for the menu.
   */
  // Menu options
  menu = new Mmenu(
    '#mobile-menu',
    {
      offCanvas: {
        position: mobileMenuPosition,
      },
      setSelected: {
        hover: true,
        parent: true,
      },
      counters: {
        add: false,
      },
      theme: mobileMenuTheme,
      navbar: {
        title: mobileMenuTitle,
      },
      navbars: [
        {
          position: 'top',
          content: ['close', 'searchfield'],
        },
        {
          breadcrumbs: {
            removeFirst: true,
          },
        },
      ],
    },
    {
      /**
       * Represents the language options for initializing the mobile menu.
       * @typedef {Object} LanguageOptions
       * @property {string} language - The language code ('en' for English, 'ar' for Arabic).
       */
      language: language, // Specify the language
    },
  );

  // Adding passive event listeners for touch events
  const addPassiveEventListener = (eventType) => {
    document.addEventListener(
      eventType,
      (event) => {
        // Empty event handler, just to set passive option
      },
      { passive: true },
    );
  };

  ['touchstart', 'touchmove'].forEach(addPassiveEventListener);

  // Adding passive event listeners to elements added by Mmenu
  document.querySelectorAll('*').forEach((element) => {
    const originalAddEventListener = element.addEventListener.bind(element);
    element.addEventListener = (type, listener, options) => {
      if (type === 'touchstart' || type === 'touchmove') {
        originalAddEventListener(type, listener, { ...options, passive: true });
      } else {
        originalAddEventListener(type, listener, options);
      }
    };
  });
};

// Call the initMenu function
initMenu();
